import { Paper, Typography, Grid2,Box } from '@mui/material';
import { useTheme } from '@mui/material/styles'; // Import the theme hook
import { alpha } from '@mui/material/styles';
const Content = () => {
    const theme = useTheme();
    return (
            <Paper sx={{backgroundColor: alpha(theme.palette.primary.main, 0.7), textAlign: "center", margin: 0, padding: 0, marginTop: "4vh"}}>
                    <Typography variant="h3" color="white">
                        Tervetuloa Työmaalle!<br/> (Welcome to the job site)
                    </Typography>
                    <br/>
                    <Typography variant="h2" color="white">
                        On this website nothing is ever ready, and it serves as a mere middleman for other pages on this site
                    </Typography>
                    <br/>  
            </Paper>

    )
}

export default Content