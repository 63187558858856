
import { Paper, Typography } from "@mui/material"

import { useTheme } from '@mui/material/styles'; // Import the theme hook
import { alpha } from '@mui/material/styles';
    
const Footer = () => {
    const theme = useTheme();
    return(
        <div>
            {/*<Paper sx={{backgroundColor: alpha(theme.palette.primary.main, 0.7), position: 'fixed',  // Fixes the Paper at the bottom
                                bottom: "1vh",          // Aligns it to the bottom of the page
                                left: "1vh",
                                width: "99vw",
                                height: "5vh"
                                
                                }}
                                >
            <Typography variant="h6" sx={{fontSize: "3vh"}} color="white">
            Potential content for footer
            </Typography>
        </Paper>*/
            }
        </div>)
}


export default Footer

