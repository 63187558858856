import { Button } from "@mui/material"

/*
* Redirects to a page using a button
*/ 

const Redirect = ({url, title}) => {

    const handleRedirect = () => {
        window.location.href = url  
    }

    return(
        <div>
            <Button onClick={handleRedirect} color="secondary.main" variant="filled">
                {title}
            </Button>
        </div>
    )
}

export default Redirect