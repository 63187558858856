import { Paper } from "@mui/material"
import { useTheme } from '@mui/material/styles'; // Import the theme hook
import { alpha } from '@mui/material/styles';
import Redirect from "./Redirect";
import { urlTable } from "../urlTable";
import {Typography} from "@mui/material";

const LinkList = () => {
    const theme = useTheme();
    return(<div>
        <Paper sx={{backgroundColor: alpha(theme.palette.primary.main, 0.7), textAlign: "center",  // Fixes the Paper at the bottom
          // Aligns it to the bottom of the page
                        margin: "2vh",
                        height: "auto",
                        }}>

            <Typography variant="h1" color="white">
                More meaningful content:
            </Typography>
            <Redirect url={urlTable.niko} title={"Go to Nikon Työmaa"} />

        </Paper>
    </div>)
}

export default LinkList