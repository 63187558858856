


import Banner from './Components/Banner';
import Content from './Components/Content';
import LinkList from './Components/LinkList';
import Footer from './Components/Footer';
import { Box, Grid2 } from '@mui/material';

const App = () => {

  return (
    <div className="App" >
      <Box>
        <Grid2 container spacing={2} sx={{height: "90vh"}}>
          <Grid2 size={{xs:2,md:1}}>
            <Banner />
          </Grid2>
          <Grid2 size={{xs:"grow",md:"grow"}}>
            <Content/>
          </Grid2>
          <Grid2 size={{xs:12,md:3}}>
            <LinkList/>
          </Grid2>
        </Grid2>
        <Footer/>
        
         
      </Box>
 
    </div>
  );
}

export default App;
