import { Divider, Grid2, Typography, Chip} from "@mui/material"
import { Typography, Drawer } from '@mui/material';
import { alpha } from '@mui/material/styles';
import React from 'react';
import Drawer from '@mui/material/Drawer';
import { useTheme } from '@mui/material/styles'; // Import the theme hook

import roadwork from "../../assets/820px-Finland_road_sign_A11.svg.png"
const Banner = () => {
  const theme = useTheme();
    return(<div>
      <div style={{ display: 'flex' }}>
      {/* Drawer acts as vertical AppBar */}
      <Drawer
        variant="permanent"
        position= "static"
        sx={{

          alignItems:"center",
          
          [`& .MuiDrawer-paper`]: {marginTop: "1vw", left: "1vw", padding: 2, width: "5vw", height: "auto", boxSizing: 'border-box', backgroundColor: alpha(theme.palette.primary.main, 0.7),  borderRadius: '40px' },
        }}
      >
        <Grid2 container spacing={1} sx={{display: "flex", justifyContent: "center", alignItems:"center"}}>
          <Grid2 size={12} sx={{display: "flex", justifyContent: "center", alignItems:"center"}}>
            <img
              src={roadwork}
              style={{
                maxWidth: "5vw", // Ensures the image scales based on the container's width
                maxHeight: "5vh", // Ensures the image scales based on the container's height
                objectFit: "contain", // Makes sure the image stays contained within the container and is not distorted
                
              }}
              />   
              
          </Grid2>
          
          
          <Grid2 size={12} sx={{display: "flex", justifyContent: "center", alignItems:"center", height: "auto", marginTop: 1}}>
            <Typography variant="h1" color="white" sx={{padding: 0,
                writingMode: "vertical-rl",
                textOrientation: "upright",
                fontSize: "3vw", // Scales the font size based on viewport width
                textAlign: "center",
                fontFamily: '"Ultra", sans-serif'}}>
                TYÖMAA
            </Typography>
          </Grid2>
         
          <Grid2 size={12} sx={{display: "flex", justifyContent: "center", alignItems:"center"}}>
            
            <img
              src={roadwork}
              style={{
                maxWidth: "5vw", // Ensures the image scales based on the container's width
                maxHeight: "5vh", // Ensures the image scales based on the container's height
                objectFit: "contain", // Makes sure the image stays contained within the container and is not distorted
              }}
              /> 
            </Grid2>
        </Grid2>
       
        
        
      </Drawer>
      {/* Main Content Area */}
    </div>
      {/*
        <AppBar position="static" elevation={0} sx={{opacity: 0.5}}>
            <Toolbar>

          
            <Typography variant="h1">
              Työmaa
            </Typography>
            <div>
              <Redirect url={urlTable.niko} title={"Go to Nikon Tyomaa"}/>
            </div>
            <div>
              <Redirect url={""} title={"Insert Others"}/>
            </div>
            </Toolbar>
          </AppBar>
          */}
    </div>)

}

export default Banner